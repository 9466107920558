
canvas {
    width: 100%;
    height: 100%;
}


@keyframes drop {
    0% {
        transform: translateY(-100px);
        opacity: 0;
    }

    90% {
        opacity: 1;
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0px);
    }
}